<template>
    <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
        <VSpinner size="medium" line-fg-color="#000" :speed="1" />
    </div>

    <div v-else class="l-settings-view l-stack l-gap-5 l-padded">
        <div class="l-stack l-gap-2">
            <FormInput
                v-model="$v.oldPassword.$model"
                type="password"
                :label="$t('oldPassword')"
                :required="true"
                :show-required-error="
                    $v.oldPassword.$dirty && !$v.oldPassword.required
                "
                :errors="serverErrors.oldPassword"
            />

            <FormInput
                v-model="$v.newPassword.$model"
                type="password"
                :label="$t('newPassword')"
                :required="true"
                :show-required-error="
                    $v.newPassword.$dirty && !$v.newPassword.required
                "
                :errors="[...errors.newPassword, ...serverErrors.newPassword]"
                @input="onNewPasswordInput"
            />
        </div>

        <FormNavigation @save="save" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { minLength, required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import FormInput from '../FormInput'
import FormNavigation from '../FormNavigation'

export default {
    name: 'ChangePasswordView',
    components: {
        FormInput,
        FormNavigation,
        VSpinner,
    },
    data() {
        return {
            isLoading: false,
            newPassword: '',
            oldPassword: '',
            errors: {
                newPassword: [],
                oldPassword: [],
            },
            serverErrors: {
                newPassword: [],
                oldPassword: [],
            },
        }
    },
    validations() {
        return {
            newPassword: {
                required,
                minLength: minLength(8),
                notNumeric: value =>
                    !value.length || value.replace(/\d/g, '').length,
            },
            oldPassword: {
                required,
            },
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUserId']),
    },
    methods: {
        async save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            this.isLoading = true
            this.serverErrors.newPassword = []
            this.serverErrors.oldPassword = []

            try {
                await httpHelper.post(
                    `/users/${this.getCurrentUserId}/set_password/`,
                    {
                        old_password: this.oldPassword,
                        new_password: this.newPassword,
                    }
                )
                this.$router.push('/map')
            } catch (error) {
                const {
                    new_password: newPasswordErros,
                    old_password: oldPasswordErros,
                } = error.response.data

                if (newPasswordErros) {
                    this.serverErrors.newPassword = newPasswordErros.map(e =>
                        this.$t(e)
                    )
                }

                if (oldPasswordErros) {
                    this.serverErrors.oldPassword = oldPasswordErros.map(e =>
                        this.$t(e)
                    )
                }
            }

            this.isLoading = false
        },
        onNewPasswordInput() {
            this.errors.newPassword = []

            if (this.$v.newPassword.$dirty) {
                if (!this.$v.newPassword.minLength) {
                    this.errors.newPassword.push(this.$t('password_too_short'))
                }

                if (!this.$v.newPassword.notNumeric) {
                    this.errors.newPassword.push(
                        this.$t('password_entirely_numeric')
                    )
                }
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "newPassword": "New password",
        "oldPassword": "Old password",
        "password_entirely_numeric": "Your password can't be entirely numeric.",
        "password_too_common": "Your password can't be a commonly used password.",
        "password_too_short": "Your password must contain at least 8 characters.",
        "password_too_similar": "Your password can't be too similar to your other personal information.",
        "password_wrong": "Wrong password"
    },
    "de": {
        "newPassword": "Neues Passwort",
        "oldPassword": "Altes Passwort",
        "password_entirely_numeric": "Ihr Passwort darf nicht ausschliesslich aus Zahlen bestehen.",
        "password_too_common": "Ihr Password ist zu einfach.",
        "password_too_short": "Ihr Passwort muss aus mindestens 8 Zeichen bestehen.",
        "password_too_similar": "Ihr Passwort darf Ihren anderen persönlichen Daten nicht zu ähnlich sein.",
        "password_wrong": "Passwort ist nicht korrekt"
    },
    "fr": {
        "newPassword": "Nouveau mot de passe",
        "oldPassword": "Ancien mot de passe",
        "password_entirely_numeric": "Votre mot de passe ne doit pas contenir que des nombres.",
        "password_too_common": "Votre mot de passe ne peut pas être un mot de passe courant.",
        "password_too_short": "Votre mot de passe doit contenir aumoins 8 caractères.",
        "password_too_similar": "Votre mot de passe ne doit pas être trop similaire à vos informations personnelles.",
        "password_wrong": "Mot de passe incorrect"
    },
    "it": {
        "newPassword": "Nuova password",
        "oldPassword": "Vecchia password",
        "password_entirely_numeric": "La tua password non può essere completamente numerica.",
        "password_too_common": "La tua password non può essere una password di uso comune.",
        "password_too_short": "La password deve contenere almeno 8 caratteri.",
        "password_too_similar": "La tua password non può essere troppo simile alle tue altre informazioni personali.",
        "password_wrong": "Password errata"
    }
}
</i18n>
